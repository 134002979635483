import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "반려동물 보유세 어떻게 생각하시나요?",
  "slug": "data13",
  "date": "2022-06-25T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb13.png",
  "featuredImg": "../images/featured/featured13.png",
  "summary": "반려동물 보유세, 동물 유기 원인. 국민의 생각은 어떠했을까?",
  "tags": ["정책", "반려동물", "법", "반려동물보유세"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`지난 10년(2012년~ 올해 4월) 동안 22만 마리의 유기⋅유실동물이 안락사 당한 비극의 배경은 첫째도 둘째도 '예산 부족이다. 버려진 개와 고양이를 살리려면 동물보호센터 등 인프라를 확충해야 하는데 예산 확보가 어려운 탓이다. 병원마다 천자만별인 동물 치료비에 공적 건강보험을 적용하는 데도 재원이 필수적이다. 이 난제들을 해결하려면 목적에 맞는 세금을 더 거둬야만 한다. 없던 세금을 새로 부과하는 정책은 정지인이나 부 입장에서는 부담스럽다. 조세 저항이 우려되기 때문이다. 하지만 국민의 생각은 사뭇 달랐다. `}</p>
    <h3>{`세금기동물 복지예산에 활용`}</h3>
    <p>{`우리나라 국민 2명 중 1명(55.6%)은 동물권향상을 위해 반려동물 보유세 신설에 동의하는 것으로 나타났다. 서울신문과 비영리 공공조시네트워크 '공공의 창'의 공동 기획으로 여론조사기관 조원씨앤아이가 실시한 동물권 보호 관련 국민인식 조사에서 이 같은 결과가 처음 확인됐다. 이번 조사는 2022년 6월 8~9일 전국 만 18세 이상 남녀 1000명을 대상으로 진행됐다.
반려동물 보유서는 반려동을 키우는 사람들에게 매년 일정액을 거둬 이들 동물 복지예산 등에 활용하는 제도다. 독일 등 동물권 선진국에서는 이미 시행하고 있다. 농림축산식품부에 따르면 지난해 기준 국내 빈려 인구는 1330만명으로 국민 4명 중 1명이다.`}</p>
    <h3>{`신설 공감하면서도 조세저항 부담`}</h3>
    <p>{`반려동물 보유세 신설은 반려인 (53.9%)과 비반려인 (57.3%) 응답 자 보두 과반이 찬성했다. 반려인들은 동물 복지와 무분별한 안락사 방지 등에 쓸 예산 확충을 위해, 비반려인들은 반려인들이 사회적 책무를 다해야 한다는 취지에서 각각 보유세 신설을 찬성한 것으로 풀이된다.
반려동물 보유세 신설에는 여성(62.3%)이 남성(48.8%)보다 더 많이 동의했다. 소득수준별 로는 자신의 소득이 ‘중하’라고 답한 응답자 중 59.8가 동의한 반면 ‘상’이라고 응답한이들은 47.3%가 동의했다. `}</p>
    <h3>{`동물 진료비 소득공제 56%가 찬성`}</h3>
    <p>{`진료비 소득공제 및 부가가치세 면제에 대해서는 응답자의 56.2%가 동의했다. 병원마다 제각각인 진료비를 통일하는 표준수가제 도입은 61.4%가 찬성했다.
이번 조사에서는 우리 국민의 동물권 인식이 과거보다 높아졌다는 점이 확인됐다. ‘동물복지 기준을 강화하고 이를 제도적으로 보장해야 한다는 의견에 얼마나 동의하느냐’고 물었더니 응답자의 65.2%가 ‘동의한다’고 답 었다.
마당개 들개 등의 중성화 수술을 정부나 지자체가 지원해 줄지 여부도 큰 이슈다. 마당개 등이 너무 많은 새끼 를 낳아 결국 안락사되는 일이 많은데 인식 부족 탓에 중성화수술을 안 시키는 보호자가 적지 않다. 이런 현실에서 응답자 중 76.2%는 중성화 수술 지원 사업 확대에 동의한다고 답했다.
또, 정부와 지자체에서 동물 복지들 맡는 조직이 커져야 한다는 데도 응답자 10명 중 6명(63.0%)이 찬성했다. `}</p>

    <h2>{`동물유기 원인은?`}</h2>
    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`동물이 버려지는 원인을 두고는 ‘소유주의 의식 부족 때문’이라고 답한 비율이 22.5%로 가장 높았다. 처벌 수위가 낮아서(20.8%) 진료비 과다(15.1%), 반려동물 매매가 쉬워서(14.2%) 등이 뒤를 이었다. `}</p>
    <h6>{`*서울신문과 비영리 공공조시네트워크 '공공의 창'의 공동 기획으로 여론조사기관 조원씨앤아이가 2022년 6월 8~9일 전국 만 18세 이상 남녀 1000명을 대상으로 진행한 자료를 바탕으로 작성되었습니다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      